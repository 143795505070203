// any CSS you import will output into a single css file (app.css in this case)
require('../css/ebureau.scss');

//AVOID LOAD CURRENT FOLDER CKFINDER
localStorage.removeItem('ckf.settings');

import 'bootstrap-icons/font/bootstrap-icons.css';
import Routing
    from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
// Moment
import moment from 'moment';

const validateIconUrl = require('../Images/icons_dashboard_v4/check.svg');

const routes = require('../../public/js/fos_js_routes.json');
global.Routing = Routing;
Routing.setRoutingData(routes);

//jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;

import Translator from 'bazinga-translator';
Translator.locale = $('html')[0].lang;

global.moment = moment;
moment.locale('fr');

// Bootstrap
require('bootstrap');

//Popovers
$(function () {
	$('[data-toggle="popover"]').popover()
})

// feather icons module
const feather = require('feather-icons');
global.feather = feather;


// général
require('simplebar/dist/simplebar');
require('../../ubold/src/js/vendor');
require('../../ubold/src/js/app');

// Overlay
require('../css/overlay.scss');
require('../js/overlay.js');

// Authentication
require('../css/authentication.scss');

jQuery(document).ready(function () {
    $("#loading").hide();
});

//Voir Minisite
jQuery(document).ready(function () {

    jQuery('#btn-action-voir-site, .btn-action-voir-site').on('click', function () {
        getVoirSite($(this));
    });

    jQuery('#btn-export-users').on('click', function () {
        exportAllUsers();
    });

		document.querySelectorAll('#btn-export-stats a').forEach(btnExportStatsEL => {
			btnExportStatsEL.addEventListener('click', async (event) => {
				if (btnExportStatsEL.classList.contains('disabled')) {
					return;
				}

				btnExportStatsEL.classList.add('disabled')
				await exportAllStats(event.target.dataset.route)
				btnExportStatsEL.classList.remove('disabled')
			})
		})

    jQuery('.container-alert .btn-close').on('click', function () {
        $(this).parent().parent().hide();
    });

    jQuery('.container-alert').delay(1500).fadeOut('slow');
});

function getVoirSite(element) {
    var id = $(element).attr('id');
    if (id == "btn-action-voir-site") {
        $(element).html('<i class="fas fa-circle-notch fa-spin"></i> '+Translator.trans("Chargement en cours"));
    }
    var url = Routing.generate('actusite_ebureau_api_v1_voir_site');

    jQuery.get(url).done(function (response) {
        window.open(response.minisite, '_blank');
        if (id == "btn-action-voir-site") {
            $(element).html('<div class="menu-top-container__icons__icon__logo"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2e2e2e" class="size-6" width="15" height="15">  "'+Translator.trans("Voir site")+'" <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>');
        }
        else {
            $(element).html(element.data('url'));
        }
    });
}

function exportAllUsers() {
    window.location = Routing.generate('actusite_ebureau_api_v1_user_export');
}

async function exportAllStats(routeName) {
	const fileName = routeName.split('statistiques_').pop()
	const response = await fetch(Routing.generate(routeName))
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const blob = await response.blob()
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.setAttribute('download', moment().format('YYYYMMDD') + '-' + fileName + '.csv')
	document.body.appendChild(link)
	link.click()
}

jQuery(document).ready(function () {
    if ($(".menu-mini-left-container").is(":visible")) {
        $(".menu-mini-left-container__elements-element a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {
                //SUBITEM CASE
                if ($(this).parent().hasClass('menu-mini-left-container__elements-element__subelement')) {
                    var element = $(this).parent().parent().parent();
                    element.addClass('element-active');
                }
                //ITEM CASE
                else {
                    var element = $(this).parent().parent();
                    $(this).parent().addClass('element-active');
                }
            }
        });
    }
});

$('.init-password').click(function() {
    var password = $('#inputPassword').val();
    var passwordConfirmation = $('#inputPasswordConfirmation').val();
    var passwordValidator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (password.match(passwordValidator)) {
        if (password != passwordConfirmation)
        {
            $('#errorConfirmationPassword').fadeIn('slow').delay(delay).fadeOut('slow');
            return false;
        }
    }
    else {
        $("#errorPassword").fadeOut(500).fadeIn(500).fadeOut(500).fadeIn(500);
        return false;
    }
});

$('.modal-confirm-action #close-modal, .modal-confirm-action #trigger-hide-modal, .overlay').click(function(event) {
    if($(".modal-confirm-action").is(":visible")){
        $('.modal-confirm-action').hide();
        Overlay.hide();
        event.preventDefault();
    }
});

$('.trigger-change-language').click(function() {
    let name = "lang";
    if(getCookie(name)) {
        document.cookie = name+'=; Max-Age=-99999999;';  
    }
    var lang = $(this).data('lang');
    var cookieDate = new Date;
    cookieDate.setFullYear(cookieDate.getFullYear() +1);
    document.cookie = 'lang='+lang+'; expires=' + cookieDate.toGMTString() + ';';
    location.reload();
})

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}